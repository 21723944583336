import { CloseModalButton, useModal } from "../../modal";
import { createSignal, Show } from "solid-js";
import { submitPlutoFeedback } from "~/server/apis/client_apis";
import { PhosphorIcon } from "~/widgets/icons";

function StarSvg(props: { fillColor?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
    >
      <path
        d="M19.0346 3.8711C19.665 2.70745 21.335 2.70745 21.9654 3.8711L25.7124 10.7873C26.1125 11.5257 26.8252 12.0435 27.6511 12.1958L35.3867 13.6222C36.6882 13.8622 37.2042 15.4504 36.2923 16.4096L30.8726 22.1104C30.2939 22.7191 30.0217 23.5569 30.1321 24.3895L31.1659 32.1872C31.3398 33.4992 29.9888 34.4808 28.7948 33.9099L21.6981 30.517C20.9405 30.1548 20.0595 30.1548 19.3019 30.517L12.2052 33.9099C11.0112 34.4808 9.66018 33.4992 9.83412 32.1872L10.8679 24.3895C10.9783 23.5569 10.7061 22.7191 10.1274 22.1104L4.70766 16.4096C3.79578 15.4504 4.31183 13.8622 5.61334 13.6222L13.3489 12.1958C14.1748 12.0435 14.8875 11.5257 15.2876 10.7873L19.0346 3.8711Z"
        stroke={props.fillColor ? props.fillColor : "#D9D9D9"}
        stroke-width="1.11111"
        fill={props.fillColor}
      />
    </svg>
  );
}

export function FeedbackModal(props: { giftId: string }) {
  const { closeModal } = useModal()!;

  const [hoveredRating, setHoveredRating] = createSignal(0);
  const [selectedRating, setSelectedRating] = createSignal(0);
  const [comment, setComment] = createSignal("");
  const [feedbackSubmitted, setFeedbackSubmitted] = createSignal(false);
  const maxLimit = 500;

  const handleMouseEnter = (rating: number) => setHoveredRating(rating);
  const handleMouseLeave = () => setHoveredRating(0);
  const handleClick = (rating: number) => setSelectedRating(rating);
  const handleTouch = (rating: number) => setHoveredRating(rating);

  const handleInput = (e: InputEvent) => {
    const input = (e.target as HTMLTextAreaElement).value;
    if (input.length <= maxLimit) {
      setComment(input);
    } else {
      (e.target as HTMLTextAreaElement).value = input.slice(0, maxLimit);
    }
  };

  const submitFeedback = async () => {
    try {
      const response = await submitPlutoFeedback(
        props.giftId,
        selectedRating(),
        comment()
      );
      if (response.status === 200) {
        setFeedbackSubmitted(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div class="relative h-screen w-full font-jakartaSans lg:h-[500px] lg:py-4 ">
      <Show when={!feedbackSubmitted()}>
        <div class="mb-7 ml-2 mt-2 lg:hidden">
          <CloseModalButton />
        </div>
      </Show>
      <div class="px-2 lg:px-4">
        <Show
          when={!feedbackSubmitted()}
          fallback={
            <div class="mx-auto flex h-[80vh] flex-col items-center justify-center lg:h-[490px]">
              <PhosphorIcon
                name="check"
                fontSize={30}
                size="bold"
                class="flex h-[60px] w-[60px] items-center justify-center rounded-full bg-[#00AF54] text-white"
              />
              <div class="text-center text-[28px] font-semibold text-white">
                Feedback submitted. <br /> Thank you.
              </div>
            </div>
          }
        >
          <div class="mb-7 text-[28px] font-semibold text-white lg:flex lg:gap-7">
            <p>Help us improve with your feedback</p>
            <CloseModalButton />
          </div>
          <div class="text-smallBold uppercase tracking-[1.1px] text-baseSecondaryLight">
            PLease rate this gift
          </div>
          <div class="mb-11 mt-3 flex gap-2">
            {Array.from({ length: 5 }, (_, index) => {
              const starRating = index + 1;
              return (
                <div
                  class="cursor-pointer"
                  onMouseEnter={() => handleMouseEnter(starRating)}
                  onMouseLeave={handleMouseLeave}
                  onClick={() => handleClick(starRating)}
                  onTouchStart={() => handleTouch(starRating)}
                  onTouchEnd={() => setHoveredRating(0)}
                >
                  <StarSvg
                    fillColor={
                      starRating <= (hoveredRating() || selectedRating())
                        ? "#FFB22C"
                        : ""
                    }
                  />
                </div>
              );
            })}
          </div>
          <div class="text-smallBold uppercase tracking-[1.1px] text-baseSecondaryLight">
            Add your comments
          </div>
          <div class="mt-3">
            <textarea
              rows={6}
              placeholder="Type here..."
              value={comment()}
              onInput={handleInput}
              maxlength={maxLimit}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  if (selectedRating() > 0 && comment().length > 0) {
                    submitFeedback();
                  }
                }
              }}
              class="no-scrollbar w-full resize-none overflow-y-auto rounded-xl border border-basePrimaryLight bg-transparent px-3 py-1.5 text-normal text-white placeholder:text-textNormal"
            ></textarea>
            <div class="flex justify-end text-[10px] font-semibold text-textNormal ">
              {comment().length}/{maxLimit}
            </div>
          </div>
        </Show>
        <div class="absolute bottom-5 w-[95%] lg:w-[91%]">
          <button
            onClick={() => {
              if (feedbackSubmitted()) {
                closeModal();
              } else {
                submitFeedback();
              }
            }}
            disabled={selectedRating() <= 0 || comment().length <= 0}
            class="flex h-11 w-full items-center justify-center rounded-[41px] bg-baseTertiaryMedium px-3 text-buttonMedium font-bold text-textDark disabled:bg-basePrimaryMedium disabled:text-baseSecondaryMedium"
          >
            {feedbackSubmitted() ? "Close" : "Submit"}
          </button>
        </div>
      </div>
    </div>
  );
}
